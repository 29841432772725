import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.videoName = ''
      this.publishStatus = ''
    }
  }
}
export default searchFrom
