<template>
  <div class="fitness">
    <el-tabs class="taps-card" v-model="form.typeId" @tab-click="tabsClick">
      <el-tab-pane
        v-for="(item, index) in category"
        :key="index"
        :label="`${item.typeName}（${
          item.countNum ? `${item.countNum}` : '0'
        }）`"
        :name="item.id"
      ></el-tab-pane>
    </el-tabs>
    <div class="search-container">
      <el-row>
        <el-form ref="form" :model="form" label-width="80px">
          <el-col :span="6">
            <el-form-item label="搜索：">
              <el-input
                clearable
                placeholder="请输入名称"
                v-model="form.videoName"
                ><i slot="suffix" class="el-input__icon el-icon-search"></i
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态：">
              <el-select v-model="form.publishStatus" placeholder="请选择">
                <el-option
                  v-for="item in publishStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form>
        <el-col :span="12" style="text-align: right">
          <el-button @click="toAddvideo" class="search-button">{{
            $t("add")
          }}</el-button>
          <el-button @click="toVideoClassification" class="search-button"
            >视频分类</el-button
          >
          <el-button @click="searchEvent" class="search-button">{{
            $t("search")
          }}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{
            $t("clean")
          }}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        border
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          prop="videoImgAddress"
          align="center"
          header-align="center"
          min-width="150"
          label="封面"
        >
          <template slot-scope="scope">
            <div class="img-content">
              <img :src="scope.row.videoImgAddress" />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="videoName"
          align="center"
          header-align="center"
          min-width="165"
          label="课程名称"
        >
        </el-table-column>
        <el-table-column
          prop="typeName"
          min-width="148"
          align="center"
          label="分类"
        >
        </el-table-column>
        <el-table-column
          prop="price"
          min-width="100"
          align="center"
          label="单价"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.price">
              {{ scope.row.price }}
            </span>
            <span v-else> 0 </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="purchases"
          align="center"
          min-width="120"
          label="购买量"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.purchases">
              {{ scope.row.purchases }}
            </span>
            <span v-else> 0 </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="collectCount"
          align="center"
          min-width="120"
          label="收藏量"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.collectCount">
              {{ scope.row.collectCount }}
            </span>
            <span v-else> 0 </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          align="center"
          min-width="120"
          label="时间"
        >
        </el-table-column>
        <el-table-column
          prop="readingCount"
          align="center"
          min-width="115"
          label="阅读量"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.readingCount">
              {{ scope.row.readingCount }}
            </span>
            <span v-else> 0 </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="collectCount"
          align="center"
          min-width="80"
          label="状态"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.publishStatus === 1" style="color: #4da2ff">
              已上架
            </span>
            <span class="err" v-else> 已下架 </span>
          </template>
        </el-table-column>
        <el-table-column prop="top" min-width="120" align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="success"
              v-if="scope.row.publishStatus === 2"
              @click="openDeleteDialog([scope.row.id], 9)"
              >上架</el-button
            >
            <el-button
              type="text"
              class="err"
              v-else
              @click="openDeleteDialog([scope.row.id], 17)"
              >下架</el-button
            >
            <el-button
              type="text"
              class="success"
              v-if="scope.$index != 0"
              @click="openDeleteDialog([scope.row.id], 18)"
              >置顶</el-button
            >
            <el-button type="text" class="success" @click="toEdit(scope.row)" v-if="scope.row.publishStatus === 2">
              编辑
            </el-button>
            <el-button
              v-if="(scope.row.purchases === null || scope.row.purchases === 0) && scope.row.publishStatus === 2"
              type="text"
              class="err"
              @click="openDeleteDialog(scope.row.id, 1)"
              >{{ $t("delete") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom-con">
        <div class="page-container">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="form.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 删除确认弹窗 -->
    <confirm-dialog
      ref="confirmDialog"
      :Language="Language"
      :show="confirmDialog.visible"
      :type="confirmDialog.type"
      @sureDialog="sureConfirmDialog"
      @closeDialog="changeConfirmDialog"
    ></confirm-dialog>
  </div>
</template>
<script>
import IndexClass from "./indexClass";
import mixin from "@/mixins/index";
import apis from "@/apis";
import confirmDialog from "@/components/confirmDialog.vue";
import { mapState } from "vuex";

export default {
  mixins: [mixin],
  components: {
    confirmDialog,
  },
  data() {
    return {
      publishStatusList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 1,
          label: "已上架",
        },
        {
          value: 2,
          label: "已下架",
        },
      ],
      form: new IndexClass("form"), // 实例化一个表单的变量
      tableData: [],
      category: [], // tab栏
      confirmDialog: {
        visible: false,
        type: 1, //1:删除 9：发布
        data: {},
      },
    };
  },
  mounted() {
    this.$http.post(apis.selectAllType).then((res) => {
      if (res.data.code === 0) {
        this.category = res.data.data;
        this.form.typeId = this.category[0].id;
        this.searchEvent();
      }
    });
  },
  activated() {
    // TODO:场馆列表
    this.$http.post(apis.selectAllType).then((res) => {
      if (res.data.code === 0) {
        this.category = res.data.data;
      }
    });
  },
  computed: {
    ...mapState(["Language", "DROPDOWNBOX", "TOKEN", "venueId"]),
  },
  methods: {
    getSelectAllType() {
      this.$http.post(apis.selectAllType).then((res) => {
        if (res.data.code === 0) {
          this.category = res.data.data;
          this.form.typeId = this.category[0].id;
        }
      });
    },
    // tabs切换
    tabsClick() {
      this.searchEvent();
    },
    // 清空按钮
    cleanForm() {
      this.form.videoName = "";
      this.form.publishStatus = "";
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
    // 查询
    searchData() {
      const formData = { ...this.form };
      this.$http.post(apis.selectVideoCoures, formData).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.tableData = res.data.rows;
          this.form.total = res.data.total;
        }
      });
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type;
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.id = "";
        this.searchEvent();
      }
    },
    /**
     * 删除和发布点击确认
     * id: 对应的id值
     * type 1：删除 9:发布
     */
    /* eslint-disable */
    openDeleteDialog(id, type) {
      this.confirmDialog.type = type;
      this.confirmDialog.data.id = id;
      this.changeConfirmDialog(true, type);
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      if (this.confirmDialog.type === 1) {
        this.modelDelete();
      } else if (
        this.confirmDialog.type === 9 ||
        this.confirmDialog.type === 17
      ) {
        this.modelHangOn();
      } else if (
        this.confirmDialog.type === 18 ||
        this.confirmDialog.type === 19
      ) {
        this.modelTop();
      }
    },
    /**
     * 删除
     */
    modelDelete() {
      this.$http
        .delete(
          `${apis.delectVideoCourse}?courseId=${this.confirmDialog.data.id}`
        )
        .then((res) => {
          if (res.data.code === 0) {
            this.$refs.confirmDialog.closeDialog();
            this.$message.success("删除成功!");
            this.getSelectAllType()
            this.searchData();
          }
        });
    },
    // 上下架
    modelHangOn() {
      let publishStatus = "";
      if (this.confirmDialog.type === 9) {
        publishStatus = 1;
      } else {
        publishStatus = 2;
      }
      this.$http
        .get(
          `${apis.upOrDownCourse}?courseId=${this.confirmDialog.data.id}&status=${publishStatus}`
        )
        .then((res) => {
          if (res.data.code === 0) {
            if (this.confirmDialog.type === 9) {
              this.$message.success("发布成功!");
            } else {
              this.$message.success("下架成功!");
            }
            this.$refs.confirmDialog.closeDialog();
            this.searchData();
          }
        });
    },
    /**
     * 置顶
     */
    modelTop() {
      this.$http
        .get(
          `${apis.topCourse}?courseId=${this.confirmDialog.data.id}`
        )
        .then((res) => {
          if (res.data.code === 0) {
            this.$message.success("置顶成功!");
            this.$refs.confirmDialog.closeDialog();
            this.searchData();
          }
        });
    },
    toVideoClassification() {
      this.$router.push({ name: "classification" });
    },
    toAddvideo() {
      this.$router.push({ name: "addVideo" });
    },
    toEdit(row) {
      this.$router.push({
        path: "editVideo",
        query: {
          Id: row.id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
.fitness {
  .el-tabs__active-bar {
    display: none;
  }
  .success {
    color: #6dd400;
  }
  .err {
    color: #e02020;
  }
}
.bottom-con {
  text-align: right;
}
.img-content {
  vertical-align: middle;
  text-align: center;
  img {
    width: 75px;
    height: 45px;
  }
}
</style>
